import styled from 'styled-components';

export const Container = styled.header`

    & {
        position: fixed;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        pointer-events: none;
    }

`;
